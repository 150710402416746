/* base.css */
body {
    margin: 0;
    height: 100vh;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

body, input {
    font-family: 'Inter', sans-serif;
}

html, body, #root {
    background: transparent !important;
}

#root {
    /*noinspection CssUnresolvedCustomProperty*/
    height: var(--visible-height);
}

#root .light .upi-widget-container, #root .light .full-page {
    background: #FFFFFF;
    color: #000000;
}

#root .full-page {
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 0;
}

#root .full-page.centered {
    text-align: center;
}

#root .full-page .container {
    max-width: 540px;
    margin: 0 auto;
}

#root .full-page h1.title{
    font-family: 'Rowdies', 'Inter', sans-serif;
    margin-top: 72px;
    font-weight: 400;
    font-size: 36px;
}

#root .full-page .banner-image {
    margin-top: 28px;
    max-width: 278px;
}

#root .full-page .body-content {
    padding: 18px;
}

#root .full-page .footer-content {
    /*noinspection CssFloatPxLength*/
    letter-spacing: 0.2px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);
    padding: 18px 18px 42px;
    position: absolute;
    max-width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
}

#root .full-page .footer-content .button, #root .full-page .footer-content .button-alt {
    /*noinspection CssFloatPxLength*/
    letter-spacing: 0.2px;
    background: #317FF3;
    border-radius: 9px;
    padding: 19px 0;
    border: none;
    width: 100%;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 16px;
}

#root .full-page .footer-content .button-alt {
    background: transparent;
    border: 1px #317FF3 solid;
    color: #317FF3;
}

#root .full-page .footer-content a {
    font-weight: 500;
    color: #000000;
}

#root .android .upi-widget-container {
    left: 16px;
    right: 16px;
    bottom: 16px;
}

#root .android .upi-widget-container, #root .android .loading-wrapper {
    border-radius: 23px;
}

hr {
    height: 1px;
    border: none;
    opacity: 0.05;
    color: #000000;
    background-color: #000000;
    margin: 0 -16px 20px;
}

/* Common styles for all themes */
/* Add other common styles here */

.upi-widget-container {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    position: relative;
    padding: 16px;
    /*noinspection CssUnresolvedCustomProperty*/
    /*height: var(--visible-height);*/
    /*height: 100vh;*/
}

.upi-widget-brand-logo {
    width: 40px;
    height: 40px;
    float: left;
}

.upi-widget-brand-name {
    margin-left: 12px;
    float: left;
}

.upi-widget-brand-name h1{
    background: #E6F6FF;
    border-radius: 22px;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.01em;
    text-align: left;
    /*noinspection CssFloatPxLength*/
    margin: 8.5px 0 0 0;
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
}

.upi-widget-brand-logo img{
    border-radius: 8px;
    width: 100%;
}

.upi-widget-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.01em;
    margin-bottom: 12px;
    margin-top: 12px;
}

.upi-widget-subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    margin-bottom: 16px;
    opacity: 0.5;
}

.upi-widget-text {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    letter-spacing: 0.01em;
    opacity: 0.63;
}

.upi-widget-text-field {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.19);
    border-radius: 8px;
    padding: 4px 12px 8px;
    margin-bottom: 18px;
    position: relative;
}

.upi-widget-text-field-focused {
    /*noinspection CssFloatPxLength*/
    border: 1.5px solid #000000;
}

.upi-widget-text-field.disabled {
    background: #F9F9F9;
}

.upi-widget-text-field label {
    top: 22px;
    left: 14px;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    line-height: 19px;
    opacity: 0.4;
}

.upi-widget-text-field input {
    border: none;
    outline: none;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    background: transparent;
    margin: 24px 0 4px;
}

.upi-widget-text-field input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0; /* Firefox */
}

.upi-widget-text-field input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0;
}

.upi-widget-text-field input::-ms-input-placeholder { /* Microsoft Edge */
    opacity: 0;
}

.upi-widget-text-field-focused input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.4;
}

.upi-widget-text-field-focused input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    opacity: 0.4;
}

.upi-widget-text-field-focused input::-ms-input-placeholder { /* Microsoft Edge */
    opacity: 0.4;
}

.upi-widget-text-field-focused-label, .upi-widget-text-field-focused label {
    left: 12px !important;
    top: 8px !important;
    font-size: 12px !important;
    line-height: 15px !important;
}

.upi-widget-text-field img.card {
    max-width: 42px;
    position: absolute;
    right: 12px;
    top: 17px;
}

.upi-widget-text-field.error {
    /*noinspection CssFloatPxLength*/
    border: 1.5px solid #FF3D3D;
    background: rgba(255, 0, 0, 0.03);
}

.upi-widget-text-error {
    margin-top: -14px;
    margin-left: 2px;
    color: #FF3D3D;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.01em;
    text-align: left;
}

.upi-widget-text-error img {
    margin-bottom: -2px;
    max-width: 14px;
}

.upi-widget-input-wrapper {
    display: flex;
    justify-content: space-between;
}

.upi-widget-footer {
    position: relative;
    margin-top: 32px;
    height: 36px;
}

.upi-widget-footer div{
    position: absolute;
    text-align: center;
    width: 40%;
}

.upi-widget-footer button {
    border: none;
    background: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #292929;
    padding: 8px;
}

.upi-widget-footer div.left {
    left: 0;
}

.upi-widget-footer div.right {
    right: 0;
}

.upi-widget-footer div.center {
    position: relative;
    text-align: center;
    width: auto;
}

.upi-widget-footer .vertical-line {
    width: 1px;
    height: 16px;
    background-color: #000000;
    position: absolute;
    opacity: 0.2;
    right: 50%;
    top: 10px;
}

.loading-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    padding: 0 32px;
    z-index: 9999;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
}

.loading-wrapper .lottie-loader {
    width: 58px;
    height: 58px;
    margin-bottom: 16px;
}

.loading-wrapper img {
    width: 58px;
    margin-bottom: 16px;
}

.loading-wrapper h1 {
    font-size: 16px;
    margin: 16px 0 4px;
    font-weight: 700;
}

.loading-wrapper p, .loading-wrapper span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    opacity: 0.6;
    margin: 0;
}

.loading-wrapper span {
    opacity: 0.32;
}

.loading-wrapper.error, .loading-wrapper.error h1 {
    margin: 8px 0 6px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
}

.loading-wrapper.error span {
    font-weight: 400;
    opacity: 0.6;
}

.loading-wrapper .upi-widget-footer {
    width: 100%;
}

.loading-wrapper .upi-widget-footer .right {
    text-align: right;
    padding: 0 16px;
}

/* ================================================================================================================== */

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.41);
}

.backdrop.blur {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

#root .ios, #root .android {
    background: transparent !important;
    background-size: contain;
    position: absolute;
    top: 0; bottom: 0;
    width: 100%;
}

.android .upi-widget-container, .ios .upi-widget-container {
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 305px;
    position: fixed;
}

.android .upi-widget-container.animate, .ios .upi-widget-container.animate {
    animation: slideUp 0.35s ease-out forwards;
}

.android .loading-wrapper .upi-widget-footer .right{
    top: 28px;
}

.ios .upi-widget-footer {
    height: 44px;
}

.ios .loading-wrapper .upi-widget-footer {
    margin-top: 48px;
}

.ios .upi-widget-footer .left button {
    text-decoration-line: underline;
    padding: 8px 2px;
    color: #FF6868;
}

.ios .upi-widget-footer .right button, .ios .upi-widget-footer .center button {
    background: #3E89FB;
    border-radius: 8px;
    padding: 8px 12px;
    color: #FFFFFF;
    min-width: 88px;
    min-height: 36px;
}

.ios .upi-widget-footer .right button .lottie-loader {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: 22px;
}

.ios .upi-widget-footer div.left {
    width: auto;
}

.ios .upi-widget-footer div.right {
    width: auto;
}

.ios .upi-widget-footer .vertical-line {
    display: none;
}

.ios .upi-widget-footer .right button.error {
    background: #FF6868;
    opacity: 0.3;
}

.ios .upi-widget-footer .right button.disabled {
    background: rgba(0, 0, 0, 0.13);
}

@media (min-width:641px) {
    #root .full-page h1.title {
        margin-top: 128px;
        font-size: 42px;
    }

    #root .full-page .banner-image {
        margin-top: 44px;
        max-width: 342px;
    }
}

/* ================================================================================================================== */

.main-app {
    color: #000000;
    background: #FFFFFF;
    font-family: 'SF Pro Display', 'Inter', sans-serif;
    position: fixed;
    padding: 0 16px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-app .back-navigator-button {
    position: fixed;
    left: 16px;
    top: 20px;
    width: 24px;
    height: 24px;
}

.main-app .back-navigator-button img {
    width: 100%;
}

.main-app .app-bar {
    padding: 22px 16px 12px;
    margin-bottom: 18px;
}

.main-app .app-bar .title {
    color: #000000;
    font-size: 16px;
    font-family: 'SF Pro Rounded', 'Inter', sans-serif;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.main-app .title{
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.main-app .subtitle {
    color: rgba(0, 0, 0, 0.70);
    line-height: 1.6;
    font-size: 14px;
    font-weight: 400;
    margin: 12px 0 0;
}

.main-app .search-field {
    border-radius: 50px;
    background: #F6F9FA;
    padding: 12px 18px 14px;
}

.main-app form {
    background: #FFFFFF !important;
}

.main-app .search-field input {
    /*noinspection CssFloatPxLength*/
    letter-spacing: 0.26px;
    outline: none;
    border: none;
    color: rgba(0, 0, 0, 0.60);
    font-size: 13px;
    font-weight: 400;
    background: #F6F9FA;
    width: 100%;
}

.main-app .upi-widget-text-field label, .main-app .upi-widget-text-field input {
    color: #000000 !important;
}

.main-app .transaction-list {
    /*noinspection CssUnresolvedCustomProperty*/
    height: var(--transaction-list-height);
    -webkit-overflow-scrolling: auto;
    overflow-scrolling: auto;
    overflow: auto;
    margin-top: 4px;
}

.main-app .transaction-list .transaction-tile {
    padding: 16px;
}

.main-app .transaction-list .transaction-tile .title {
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    margin: 0;
}

.main-app .transaction-list .transaction-tile .subtitle {
    color: #8C8C8C;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.main-app .transaction-list .transaction-tile .trailing {
    float: right;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    margin-top: 8px;
}

.main-app .transaction-list .transaction-tile .trailing img {
    width: 13px;
    height: 13px;
}

.main-app .form-field-group .upi-widget-text-field {
    border: 1px rgba(0, 0, 0, 0.50) solid;
    border-radius: 0;
}

.main-app .upi-widget-text-field {
    padding: 4px 16px 8px;
}

.main-app .form-field-group .upi-widget-text-field.upi-widget-text-field-focused label,
.main-app .form-field-group .upi-widget-text-field .upi-widget-text-field-focused-label {
    left: 16px !important;
}

.main-app .form-field-group .upi-widget-text-field input {
    font-weight: 500;
}

.main-app .form-field-group .upi-widget-text-field select {
    margin: 24px -3px 4px;
    outline: none;
    border: none;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.main-app .form-field-group .upi-widget-text-field:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 0;
}

.main-app .form-field-group .upi-widget-text-field:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.main-app .retry-counter {
    color: rgba(0, 0, 0, 0.50);
    font-size: 13px;
    font-weight: 400;
}

.main-app .retry-counter a {
    color: #6E9BEC;
    font-weight: 600;
    text-decoration: underline;
}

.main-app .sliver-app-bar {
    background-color: #E0F0FF;
    padding: 50px 32px 40px;
    text-align: center;
}

.main-app .sliver-app-bar .title {
    color: #000000;
    font-size: 32px;
    font-family: 'Instrument Sans', 'Inter', sans-serif;
    font-weight: 500;
}

.main-app .sliver-app-bar img {
    width: 48px;
}

.main-app .sliver-app-bar .title span {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.main-app .sliver-app-bar .title span:last-child {
    font-weight: 500;
}

.main-app .sliver-app-bar .description {
    font-family: 'Inter', sans-serif;
    color: rgba(0, 0, 0, 0.50);
    font-size: 13px;
    font-weight: 500;
}

.main-app .transaction-details {
    padding: 25px 16px;
}

.main-app .transaction-details .row {
    margin-bottom: 32px;
}

.main-app .transaction-details .row div {
    font-family: 'Inter', sans-serif;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    word-wrap: break-word
}

.main-app .transaction-details .row .label {
    font-family: 'Inter', sans-serif;
    color: rgba(0, 0, 0, 0.50);
    margin-bottom: 4px;
    font-size: 13px;
}

.main-app .rounded-button {
    font-family: 'SF Pro Rounded', 'Inter', sans-serif;
    background: #008CFF;
    border-radius: 50px;
    padding: 14px 16px;
    width: 100%;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
}

.main-app .rounded-button.bottom {
    position: absolute;
    right: 16px;
    left: 16px;
    width: auto;
    bottom: 36px;
}

.main-app .rounded-button.disabled {
    background: rgba(0, 0, 0, 0.1);
}

.main-app .footer-message {
    /* TODO HERE */
}
