/* android-light.css */
@import 'light-theme.css';

.dark hr {
    opacity: 0.1;
    color: #FFFFFF;
    background-color: #FFFFFF;
}

#root .dark:has(body) {
    background: #1B1D1F;
}

#root .dark, .dark form {
    background: #1B1D1F;
    color: #FFFFFF;
}

#root .dark .upi-widget-container, #root .dark .full-page {
    background: #1B1D1F;
    color: #FFFFFF;
}

#root .dark .full-page .footer-content {
    color: rgba(225, 225, 225, 0.7);
}

#root .dark .full-page .footer-content a {
    color: #FFFFFF;
}

#root .dark .full-page .footer-content .button-alt {
    border-color: #FFFFFF;
    color: #FFFFFF;
}

.dark .upi-widget-brand-name h1{
    background: #404243;
}

.dark .upi-widget-text-field {
    border-color: rgba(255, 255, 255, 0.25);
}

.dark .upi-widget-text-field label, .dark .upi-widget-text-field input {
    color: #FFFFFF;
}

.dark .upi-widget-text-field.error {
    border-color: #FF0000;
    background: rgba(255, 0, 0, 0.1);
}

.dark .upi-widget-text-field-focused {
    /*noinspection CssFloatPxLength*/
    border: 1.5px solid #FFFFFF;
}

.dark .upi-widget-text-field.disabled {
    background: #23262A;
}

.dark .upi-widget-footer button {
    color: #FFFFFF;
}

.dark .upi-widget-footer .vertical-line {
    background-color: #FFFFFF;
    opacity: 0.3;
}

.android.dark .upi-widget-footer .right button {
    color: #FFBBFF;
}

.ios.dark .upi-widget-footer .right button.disabled {
    color: rgba(255, 255, 255, 0.26);
    background: #353535;
}

.dark .loading-wrapper {
    background: #1B1D1F;
}

.light .loading-wrapper .lottie-loader.success {
    width: 80px;
    height: 80px;
    margin-bottom: 0;
}
